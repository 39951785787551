<template>
  <div v-if="packagesData"
    class="flex flex-col items-stretch w-full lg:grid lg:grid-cols-3 flex-wrap gap-3 lg:gap-6"
  >
    <div
        v-for="(packageItem,index) in packagesData" :key="index"
        class="w-full lg:w-auto border border-black flex flex-col col-span-1 justify-items-center rounded
          lg:group-hover:scale-101"
        :class="{
          'px-3 lg:px-6 py-3 lg:py-9 space-y-3 lg:space-y-9': index===1,
          'lg:my-6 px-3 py-3 lg:py-6 space-y-3 lg:space-y-6': index!=1,
        }"
    >
      <div class="font-semibold text-center text-base"
        :class="{'text-xl': index===1}"
      >
        {{ trans(packageItem.name) }}
      </div>
      <hr class="border-grayedHR">
        <div class="text-center text-xl flex flex-row lg:flex-col justify-center gap-3">
          <div class="">
            <span class="font-light text-4xl text-redAccent" :class="{'text-5xl': index===1}">{{ packageItem.priceFormatted }}</span><span class="text-base text-redAccent" :class="{'text-xl': index===1}"> {{trans('LABEL:currency')}}</span><span class="block lg:hidden text-black text-base" :class="{'text-xl': index===1}">{{trans('LABEL:daysFor') }} {{ packageItem.days}} {{trans('LABEL:days') }}</span>
          </div>
          <div class="hidden lg:block text-black text-base" :class="{'text-xl': index===1}">{{trans('LABEL:daysFor') }} {{ packageItem.days}} {{trans('LABEL:days') }}</div>
        </div>
        <div class="text-center text-base text-grayed ":class="{'text-xl': index===1}">
            {{packageItem.pricePerDayFormatted}} {{trans('LABEL:currency')}}/{{trans('LABEL:day')}}
        </div>

        <div class="text-center text-xl" :class="{'text-2xl': index===1}" v-if="packageItem.salePrice">
            <span class="text-redAccent">{{ packageItem.salePriceFormatted }}</span>
        </div>
        <div class="w-full flex flex-row justify-center">
          <div class="text-center text-xl w-2/3 lg:w-full">
            <TextField
              :custom-class="'color-white'"
              v-model="discountCode[packageItem.id]"
              :placeholder="trans('LABEL:DiscountCode')"
            >
            </TextField>
          </div>
        </div>
        <div class="text-center">
        <Button
          :variant="{'buttonRedOutlined': index!=1, 'buttonRed': index===1}"
          :custom-class="'uppercase'"
          @clicked="createPaymentAction(packageItem.id)"
          :loading="subscriptionPending === packageItem.id"
        >
          {{ trans('LABEL:Choose') }}
        </Button>
      </div>
    </div>
  </div>
  <div v-else class="w-full flex justify-center">
    <Loader small no-border/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Button from "@/utils/crud/components/Button";
import TextField from "@/utils/crud/components/field-types/Text";
import Loader from "@/views/Loader";

export default {
  name: "SubscriptionPackages",
  components: {Button, Loader, TextField},
  data() {
    return {
      discountCode: {},
    }
  },
  computed: {
    ...mapGetters('subscriptions', ['packagesData', 'subscriptionPending'])
  },
  methods: {
    ...mapActions('subscriptions', ['createPaymentRequest']),

    createPaymentAction(packageId) {
      let data = {
        packageId: packageId,
        discountCode: this.discountCode[packageId],
      };
      this.createPaymentRequest(data)
    }
  }
}
</script>

